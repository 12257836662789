import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const testimonials = [
  {
    title: "I used SyllaTutors for my daughter - extremely impressed with all their help - will be continuing with them for my daughter into her A-Levels",
    description: "Muslih - parent",
  },
  {
    title: "Hasan is my daughter's tutor. She went from just over average in maths to top of the class and it's all down to the brilliant work Hasan has put in. Highly recommended to all parents",
    description: "Sihem",
  },
  {
    title: "Hey just wanted to let you know I got my results today and got 8s and 9s in all the sciences - thanks for all the help",
    description: "Amaan - student",
  },
  
];

class Testimonials extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="page-section" id="testimonials">
          <div className="container">
            <h1 className="text-uppercase  text-center" style={{color:"#000000", fontWeight:600}}>Testimonials</h1>
            <div className="testimonial-carousel">
              <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={5000} // Set the interval for auto-play in milliseconds
              >
                {testimonials.map((testimonial, index) => (
                  <div key={index} className="testimonial-item">
                    <div className="testimonial-content">
                      <h3>{testimonial.title}</h3>
                      <p>{testimonial.description}</p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonials;
