import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import Swal from 'sweetalert2';

function BuySessionDialog({ isOpen, onClose, onSubmit }) {
  const [quantity, setQuantity] = useState(1);
function callswiral(){
    Swal.fire({
        icon: 'error',
        title: 'Payment problem',
        text: 'Nework problem or insufficient balanc',
      });
}
  // Load Stripe
  const stripePromise = loadStripe('pk_live_51Nhe2QCM1Y3qdyF9WObKEiDZOzIMQBSInRaXBjqmoOJWuzxBm7UbHI0LHAa9ncdKlK8hnChgZJ9hAxlBIsKvdUWS00biICXxdv'); // Replace with your Stripe publishable key
  const priceofproduct='price_1OUFJ2CM1Y3qdyF99LggGmE3'
  const handleSubmit = async () => {
    onSubmit(quantity);
    localStorage.setItem('quantity',quantity);
    console.log("console"+localStorage.getItem('quantity'));
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [
        {
            price: priceofproduct, // Replace with your Stripe price ID
          quantity: parseInt(quantity),
        },
      ],
      successUrl: `https://syllatutors.com/success`, // Replace with your success URL
      cancelUrl: `https://syllatutors.com/cancel`, // Replace with your cancel URL
    });

    if (error) {
      console.error('Error redirecting to Stripe Checkout:', error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };

  return (
    <div style={{ display: isOpen ? 'block' : 'none', position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '9999' }}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
        <h2>Enter Quantity</h2>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
        <button onClick={handleSubmit}>Buy</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default BuySessionDialog;
